<template>
  <div v-if="isLoaded">

    <!-- new_transmitter events -->
    <div class="card">
      <h2 class="card__title">
        <i class="fas fa-broadcast-tower"></i>
        {{ $t('pages.pendingEvent_index.new_transmitter-title', { count: this.events.newTransmitter.length }) }}
      </h2>

      <p class="card__description">{{ $t('pages.pendingEvent_index.new_transmitter-description') }}</p>
      
      <!-- No events-->
      <div v-if="this.events.newTransmitter.length === 0" class="italic text-muted">
        {{ $t('pages.culture_events.no-event-now') }}
      </div>
      
      <!-- Display events-->
      <div v-else class="flex flex-wrap">
        <div v-for="(event, i) in events.newTransmitter" :key="event.id" class="event-card">
          <new-transmitter-event :event="event" :cultures="cultures" @acquitted="oneEventAcquitted(event, i)" />
        </div>
      </div>
    </div>

    <!-- All events by culture -->
    <div v-if="culturesTable.data" class="card mt-4">
      <h2 class="card__title">
        <i class="fas fa-leaf"></i>
        {{ $t('pages.pendingEvent_index.eventsByCulture-title', { count: culturesTable.data.length }) }}
      </h2>
      <data-table :columns="culturesTable.columns" :data="culturesTable.data" />
    </div>

  </div>
</template>

<script>
import { apiGetRequest, displayAlertSuccess } from "../../../utils";
import {
  API_CULTURES_INDEX,
  API_EVENTS_PENDING, API_FARMERS_CULTURES_SUFFIX, API_FARMERS_SHOW, API_USERS_INDEX
} from "../../../utils/constants";
import NewTransmitterEvent from "../../../components/events/NewTransmitterEvent";
import DataTable from "../../../components/general/datatable/data-table";

export default {
  name: "PendingEventIndex",
  components: { NewTransmitterEvent, DataTable },
  data: function () {
    return {
      userWeWantId: this.$route.query.userId,
      isLoaded: true,
      cultures: [],
      events: {
        newTransmitter: []
      },
      culturesTable: {
        columns: {
          name: this.$t('words.name'),
          type: this.$t('words.type'),
          nbPendingEvents: this.$t('words.nbPendingEvents'),
          actions: this.$t('words.actions'),
        },
        data: null
      }
    }
  },
  mounted() {
    this.isLoaded = false;

    let allCulturesRoute = API_CULTURES_INDEX;
    let allPendingEventsRoute = API_EVENTS_PENDING;

    if (this.$store.getters.userIsAdmin) {
      allCulturesRoute = API_FARMERS_SHOW + "/" + this.userWeWantId + API_FARMERS_CULTURES_SUFFIX;
      allPendingEventsRoute = API_USERS_INDEX + "/" + this.userWeWantId + "/events/pending";
    }

    apiGetRequest(allCulturesRoute)
      .then((res) => {
        this.cultures = res.data;

        this.culturesTable.data = res.data.filter((c) => c.nbPendingEvents > 0).map((c) => {
          c.type = `${this.$t('backend_trans_keys.' + c.typeTransKey)} (${this.$t('backend_trans_keys.' + c.varietyTransKey)})`;
          c.actions = {
            see: {
              to: { name: 'culture_events', params: { id: c.id } },
              text: this.$t('words.seeEvents')
            }
          };
          return c;
        });

        apiGetRequest(allPendingEventsRoute)
          .then((res) => {
            this.events.newTransmitter = res.data.newTransmitterEvents;
            this.isLoaded = true;
          });
      });
  },
  methods: {
    oneEventAcquitted: function (event, arrayIndex) {
      this.events.newTransmitter.splice(arrayIndex, 1);
      this.$store.commit('setPendingEventsCount', this.$store.state.pendingEventsCount - 1);
      this.displaySuccessAlert(1);
    },
    displaySuccessAlert: function (count) {
      const successMsg = this.$tc('pages.culture_events.success-message', count);
      displayAlertSuccess(successMsg);
    },
  }
}
</script>

<style scoped>
.card {
  background-color: #fff;
  border: 1px solid #ddd; /* Bordure */
  border-radius: 10px; /* Coins arrondis */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Ombre */
  padding: 20px; 
  margin-bottom: 20px; /* Espacement entre les sections */
}

.card__title {
  font-size: 1.1rem; /* Taille du titre */
  margin-bottom: 15px; /* Espacement sous le titre */
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #333; /* Couleur du titre */
  text-decoration: none;
}

.card__title i {
  margin-right: 10px; /* Espacement entre l'icône et le texte */
  font-size: 1.3rem; /* Taille de l'icône */
  color: #4CAF50; /* Couleur de l'icône */
}

.card__description {
  font-size: 1rem;
  color: #777; /* Couleur du texte descriptif */
  margin-bottom: 20px;
}

.event-card {
  width: 100%;
  margin: 10px;
  padding: 10px;
  background-color: #f9f9f9; 
  border-radius: 8px;
}

/* Ombre plus prononcée au survol de la section */
.card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
}

.text-muted {
  color: #aaa;
}
</style>