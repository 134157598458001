<template>
  <ul>
    <router-link :to="{name: 'inventory'}">
      <li class="menu-item"><i class="fas fa-list icon"></i> <span class="opacity-100">{{ $t('pages.inventory.title') }}</span></li>
    </router-link>
    <router-link :to="{name: 'user_index'}">
      <li class="menu-item"><i class="fas fa-users icon"></i> {{ $t('pages.user_index.title') }}</li>
    </router-link>
    <router-link :to="{name: 'transfer'}">
      <li class="menu-item"><i class="fas fa-exchange-alt icon"></i> {{ $t('pages.transfer.title') }}</li>
    </router-link>
    <router-link :to="{name: 'export_csv'}">
      <li class="menu-item"><i class="fas fa-download icon"></i> {{ $t('pages.export_csv.title') }}</li>
    </router-link>
    <router-link v-if="isServerLogsDisplayable" :to="{name: 'server_logs'}">
      <li class="menu-item"><i class="fas fa-file-alt icon"></i> {{ $t('pages.server_logs.title') }}</li>
    </router-link>
    <!-- Not ready
    <a href="">
      <li class="menu-item"><i class="fas fa-user-cog icon"></i> {{ $t('pages.installers.title') }}</li>
    </a>
    -->
  </ul>
</template>

<script>
export default {
  name: "admin-menu",
  data: function() {
    return {
      isServerLogsDisplayable: process.env.VUE_APP_SERVER_LOGS_DISPLAYABLE === 'true'
    }
  }
}
</script>

<style scoped>

</style>
