<template>
  <ValidationObserver ref="userForm" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmitWithData)">

      <!-- Champ Email -->
      <div class="mt-3 mb-3">
        <ValidationProvider :name="forms.email.label" rules="required|max:255|email" v-slot="{ errors }">
          <label for="email" class="form__label">
            <i class="fas fa-envelope mr-2"></i>{{ forms.email.label }}<Required />
          </label>
          <input id="email" type="text" class="form__input w-full" v-model="forms.email.value" />
          <p class="form__error">{{ errors[0] }}</p>
        </ValidationProvider>
      </div>

      <!-- Champs Prénom et Nom -->
      <div class="grid grid-cols-2 gap-3 mt-3 mb-3">
        <div>
          <ValidationProvider :name="forms.firstname.label" rules="required|max:255" v-slot="{ errors }">
            <label for="firstname" class="form__label">
              <i class="fas fa-user mr-2"></i>{{ forms.firstname.label }}<Required />
            </label>
            <input id="firstname" type="text" class="form__input w-full" v-model="forms.firstname.value" />
            <p class="form__error">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
        <div>
          <ValidationProvider :name="forms.lastname.label" rules="required|max:255" v-slot="{ errors }">
            <label for="lastname" class="form__label">
              <i class="fas fa-user mr-2"></i>{{ forms.lastname.label }}<Required />
            </label>
            <input id="lastname" type="text" class="form__input w-full" v-model="forms.lastname.value" />
            <p class="form__error">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
      </div>

      <!-- Champs Adresse (NPA, Ville, Rue) -->
      <div class="grid grid-cols-6 gap-3 mt-3 mb-3">
        <div class="col-span-1">
          <ValidationProvider :name="forms.npa.label" rules="required|max:255|numeric|min_value:1" v-slot="{ errors }">
            <label for="npa" class="form__label">
              <i class="fas fa-map-marker-alt mr-2"></i>{{ forms.npa.label }}<Required />
            </label>
            <input id="npa" type="number" class="form__input w-full" v-model="forms.npa.value" />
            <p class="form__error">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>

        <div class="col-span-2">
          <ValidationProvider :name="forms.city.label" rules="required|max:255" v-slot="{ errors }">
            <label for="city" class="form__label">
              <i class="fas fa-city mr-2"></i>{{ forms.city.label }}<Required />
            </label>
            <input id="city" type="text" class="form__input w-full" v-model="forms.city.value" />
            <p class="form__error">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>

        <div class="col-span-3">
          <ValidationProvider :name="forms.street.label" rules="required|max:255" v-slot="{ errors }">
            <label for="street" class="form__label">
              <i class="fas fa-road mr-2"></i>{{ forms.street.label }}<Required />
            </label>
            <input id="street" type="text" class="form__input w-full" v-model="forms.street.value" />
            <p class="form__error">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
      </div>

      <!-- Champ Pays -->
      <div class="grid grid-cols-6 gap-3 mt-3 mb-3">
        <div class="col-span-1">
          <ValidationProvider :name="forms.country.label" rules="required|max:255" v-slot="{ errors }">
            <label for="country" class="form__label">
              <i class="fas fa-flag mr-2"></i>{{ forms.country.label }}<Required />
            </label>
            <input id="country" type="text" class="form__input w-full" v-model="forms.country.value" />
            <p class="form__error">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
      </div>

      <!-- Champ Rôle (pour l'utilisateur non profil) -->
      <div v-if="!profile" class="mt-3 mb-3">
        <label for="role" class="form__label">
          <i class="fas fa-user-tag mr-2"></i>{{ forms.role.label }}<Required />
        </label>
        <select v-model="forms.role.value" class="form__input w-full">
          <option v-for="role in allRoles" :key="role.value" :value="role.value.toUpperCase()">{{ role.label }}</option>
        </select>
      </div>

      <!-- Bouton pour la modification des données -->
      <button class="mt-6 btn btn--success">
        <i class="fas fa-check mr-2"></i>{{ !isEdit ? $t('pages.user_create.validate') : $t('pages.user_update.validate') }}
      </button>

    </form>
  </ValidationObserver>
</template>

<script>
import { ADMIN_ROLE, FARMER_ROLE } from "@/utils/constants";
import Required from "@/components/forms/Required.vue";

export default {
  name: "UserForm",
  components: { Required },
  props: {
    onSubmit: { type: Function, required: true },
    errorsForm: { type: Object, required: false },
    userInfo: { type: Object, required: false },
    isEdit: { type: Boolean, required: false, default: false },
    profile: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      forms: {
        email: { label: this.$t('words.email'), value: this.userInfo ? this.userInfo.email : null},
        firstname: { label: this.$t('words.firstname'), value: this.userInfo ? this.userInfo.firstname : null },
        lastname: { label: this.$t('words.lastname'), value: this.userInfo ? this.userInfo.lastname : null },
        npa: { label: this.$t('words.npa'), value: this.userInfo ? this.userInfo.npa : null },
        country: { label: this.$t('words.country'), value: this.userInfo ? this.userInfo.country : null },
        city: { label: this.$t('words.city'), value: this.userInfo ? this.userInfo.city : null },
        street: { label: this.$t('words.street'), value: this.userInfo ? this.userInfo.street : null },
        role: { label: this.$t('words.role'), value: FARMER_ROLE.toUpperCase() },
      },
      allRoles: [
        { label: this.$t('words.farmer'), value: FARMER_ROLE },
        { label: this.$t('words.admin'), value: ADMIN_ROLE },
      ],
    };
  },
  watch: {
    errorsForm: {
      handler(errorsForm) {
        this.$refs.userForm.setErrors(errorsForm);
      },
    },
    userInfo: {
      handler(userInfo) {
        for (const field in userInfo) {
          this.forms[field].value = userInfo[field];
        }
      },
    },
  },
  methods: {
    onSubmitWithData() {
      const data = Object.fromEntries(Object.entries(this.forms).map(([key, { value }]) => [key, value]));
      this.onSubmit(data);
    },
  },
};
</script>

<style scoped>
/* Styles personnalisés */
</style>