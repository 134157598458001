<template>
  <modal :visible="visible" :toggle="toggle">
    <!-- Material type (transmitter / sensor) -->
    <div>
      <h1 class="mb-2">{{ forms.materialType.label }}</h1>
      <select v-model.number="forms.materialType.value" @change="materialTypeOnChange" class="form__input w-full">
        <option v-for="materialType in materialTypes" :key="materialType.value" :value="materialType.value" :disabled="materialType.value === 0">{{ materialType.text }}</option>
      </select>
    </div>

    <!-- Want create: Transmitter -->
    <div v-if="forms.materialType.value === materialTypes.transmitter.value" class="mt-10">
      <ValidationObserver ref="transmitterForm" v-slot="{ handleSubmit, /*invalid*/}">
        <form @submit.prevent="handleSubmit(submitTransmitterForm)">
          <div class="flex mt-2">
            <!-- Id -->
            <div class="w-64 mr-6">
              <ValidationProvider :name="forms.transmitter.id.label" rules="required|numeric|max:255|min_value:1" v-slot="{errors}">
                <label class="form__label">{{ forms.transmitter.id.label }}<Required/></label>
                <input v-model="forms.transmitter.id.value" class="form__input w-full" type="number" min="1">
                <p class="form__error">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <!-- TTN ID -->
            <div class="w-64">
              <ValidationProvider :name="forms.transmitter.ttnId.label" rules="required|max:255" v-slot="{errors}">
                <label class="form__label">{{ forms.transmitter.ttnId.label }}<Required/></label>
                <input v-model="forms.transmitter.ttnId.value" class="form__input w-full">
                <p class="form__error">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
          </div>

          <!-- Transmitter type -->
          <div class="w-full mt-2">
            <ValidationProvider :name="forms.transmitter.type.label" rules="required" v-slot="{errors}">
              <label class="form__label">{{ forms.transmitter.type.label }}<Required/></label>
              <!-- Waiting the transmitter types are all loaded-->
              <div v-if="transmitterTypes !== null">
                <select v-model.number="forms.transmitter.type.value" class="form__input w-full">
                  <option v-for="transmitterType in transmitterTypes" :key="transmitterType.id" :value="transmitterType.id">{{ $t('backend_trans_keys.' + transmitterType.transKey) }}</option>
                </select>
                <p class="form__error">{{ errors[0] }}</p>
              </div>
              <!-- Loading -->
              <div v-else>
                <i class="fas fa-spinner fa-spin"></i>
              </div>
            </ValidationProvider>
          </div>

          <!-- Transmitter with Flowmeter -->
          <div class="flex mt-2" v-if="forms.transmitter.type.value === transmitterTypeFlowMeter">
            <!-- ID Flowmeter -->
            <div class="w-64 mr-6">
              <ValidationProvider :name="forms.transmitter.flowmeter.label" rules="required|numeric|max:255|min_value:1" v-slot="{errors}">
                <label class="form__label">{{ forms.transmitter.flowmeter.label }}<Required/></label>
                <input v-model="forms.transmitter.flowmeter.value" class="form__input w-full" type="number" min="1">
                <p class="form__error">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
          </div>

          <!-- Only if we selected Weather station => fill the linked sensors -->
          <div v-if="forms.transmitter.type.value === transmitterTypeWeatherStation || forms.transmitter.type.value === transmitterTypeWeatherStationPyranometer 
              || forms.transmitter.type.value === transmitterTypeWeatherStationPluviometer || forms.transmitter.type.value === transmitterTypeWeatherStationPyranometerAndPluviometer">
            <div class="flex mt-2">

              <!-- ID Anemometer -->
              <div class="case-id-width mr-6">
                <ValidationProvider :name="forms.transmitter.anemometer.label" rules="required|numeric|max:255|min_value:1" v-slot="{errors}">
                  <label class="form__label">{{ forms.transmitter.anemometer.label }}<Required/></label>
                  <input v-model="forms.transmitter.anemometer.value" class="form__input w-full" type="number" min="1">
                  <p class="form__error">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>

              <!-- ID Hygrometer -->
              <div class="case-id-width mr-6">
                <ValidationProvider :name="forms.transmitter.hygrometer.label" rules="required|numeric|max:255|min_value:1" v-slot="{errors}">
                  <label class="form__label">{{ forms.transmitter.hygrometer.label }}<Required/></label>
                  <input v-model="forms.transmitter.hygrometer.value" class="form__input w-full" type="number" min="1">
                  <p class="form__error">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>

              <!-- ID Barometer -->
              <div class="case-id-width">
                <ValidationProvider :name="forms.transmitter.barometer.label" rules="required|numeric|max:255|min_value:1" v-slot="{errors}">
                  <label class="form__label">{{ forms.transmitter.barometer.label }}<Required/></label>
                  <input v-model="forms.transmitter.barometer.value" class="form__input w-full" type="number" min="1">
                  <p class="form__error">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>

            <!-- Weather Station with Pyranometer -->
            <div class="flex mt-2" v-if="forms.transmitter.type.value === transmitterTypeWeatherStationPyranometer">
              <!-- ID Pyranometer -->
              <div class="case-id-width">
                <ValidationProvider :name="forms.transmitter.pyranometer.label" rules="required|numeric|max:255|min_value:1" v-slot="{errors}">
                  <label class="form__label">{{ forms.transmitter.pyranometer.label }}<Required/></label>
                  <input v-model="forms.transmitter.pyranometer.value" class="form__input w-full" type="number" min="1">
                  <p class="form__error">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>

            <!-- Weather Station with Pluviometer -->
            <div class="flex mt-2" v-if="forms.transmitter.type.value === transmitterTypeWeatherStationPluviometer">
              <!-- ID Pluviometer -->
              <div class="case-id-width">
                <ValidationProvider :name="forms.transmitter.pluviometer.label" rules="required|numeric|max:255|min_value:1" v-slot="{errors}">
                  <label class="form__label">{{ forms.transmitter.pluviometer.label }}<Required/></label>
                  <input v-model="forms.transmitter.pluviometer.value" class="form__input w-full" type="number" min="1">
                  <p class="form__error">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>


            <!-- Weather Station with Pyranometer and Pluviometer -->
            <div class="flex mt-2" v-if="forms.transmitter.type.value === transmitterTypeWeatherStationPyranometerAndPluviometer">
              <!-- ID Pyranometer -->
              <div class="w-64 mr-6">
                <ValidationProvider :name="forms.transmitter.pyranometer.label" rules="required|numeric|max:255|min_value:1" v-slot="{errors}">
                  <label class="form__label">{{ forms.transmitter.pyranometer.label }}<Required/></label>
                  <input v-model="forms.transmitter.pyranometer.value" class="form__input w-full" type="number" min="1">
                  <p class="form__error">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>

              <!-- ID Pluviometer -->
              <div class="w-64">
                <ValidationProvider :name="forms.transmitter.pluviometer.label" rules="required|numeric|max:255|min_value:1" v-slot="{errors}">
                  <label class="form__label">{{ forms.transmitter.pluviometer.label }}<Required/></label>
                  <input v-model="forms.transmitter.pluviometer.value" class="form__input w-full" type="number" min="1">
                  <p class="form__error">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <!-- Create button -->
          <button v-if="transmitterTypes !== null" class="mt-6 btn btn--success"><i class="fas fa-check mr-2"></i>{{ $t('pages.inventory.addMaterialModal.forms.validate') }}</button>
        </form>
      </ValidationObserver>
    </div>

    <!-- Want create: Sensor -->
    <div v-else-if="forms.materialType.value === materialTypes.sensor.value" class="mt-10">
      <ValidationObserver ref="sensorForm" v-slot="{ handleSubmit, /*invalid*/}">
        <form @submit.prevent="handleSubmit(submitSensorForm)">
          <div class="flex mt-2">
            <!-- Id -->
            <div class="w-64 mr-6">
              <ValidationProvider :name="forms.sensor.id.label" rules="required|numeric|max:255|min_value:1" v-slot="{errors}">
                <label class="form__label">{{ forms.sensor.id.label }}<Required/></label>
                <input v-model="forms.sensor.id.value" class="form__input w-full" type="number" min="1">
                <p class="form__error">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <!-- TTN ID -->
            <div class="w-64">
              <ValidationProvider :name="forms.sensor.type.label" rules="required" v-slot="{errors}">
                <label class="form__label">{{ forms.sensor.type.label }}<Required/></label>
                <!-- Waiting the Sensors types are all loaded-->
                <div v-if="sensorTypes !== null">
                  <select v-model.number="forms.sensor.type.value" class="form__input w-full">
                    <option v-for="sensorType in sensorTypes" :key="sensorType.id" :value="sensorType.id">{{ $t('backend_trans_keys.' + sensorType.transKey) }}</option>
                  </select>
                  <p class="form__error">{{ errors[0] }}</p>
                </div>
                <!-- Loading -->
                <div v-else>
                  <i class="fas fa-spinner fa-spin"></i>
                </div>
              </ValidationProvider>
            </div>
          </div>

          <!-- Create button -->
          <button v-if="sensorTypes !== null" class="mt-6 btn btn--success"><i class="fas fa-check mr-2"></i>{{ $t('pages.inventory.addMaterialModal.forms.validate') }}</button>
        </form>
      </ValidationObserver>
    </div>
  </modal>
</template>

<script>
import modal from "./modal";
import Required from "../forms/Required";
import debounce from 'lodash/debounce';
import {apiGetRequest, apiPostRequest, errorResponseIsFormError} from "../../utils";
import {
  API_SENSOR_TYPES_ALL,
  API_TRANSMITTER_TYPES_ALL,
  API_USERS_SENSORS_STORE,
  API_USERS_TRANSMITTERS_STORE
} from "../../utils/constants";

export default {
  name: "addMaterialModal",
  components: {modal, Required},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    toggle: {
      type: Function,
      required: true
    },
    userId: {
      type: [Number, String],
      required: true
    }
  },
  data: function() {
    return {
      materialTypes:{
        none: {
          text: this.$t('pages.inventory.addMaterialModal.materialTypes.none'),
          value: 0,
        },
        transmitter: {
          text: this.$t('pages.inventory.addMaterialModal.materialTypes.transmitter'),
          id: {value: ''},
          value: 1,
        },
        sensor: {
          text: this.$t('pages.inventory.addMaterialModal.materialTypes.sensor'),
          value: 2,
        }
      },
      transmitterTypes: null, // Only used for transmitter form
      sensorTypes: null, // Only used for sensor form
      //Numéro définis dans le backend (Seeds.java)
      transmitterTypeWeatherStation: 2,
      transmitterTypeWeatherStationPyranometer: 3,
      transmitterTypeWeatherStationPluviometer: 4,
      transmitterTypeWeatherStationPyranometerAndPluviometer: 5,
      transmitterTypeFlowMeter: 6, 

      forms: {
        materialType: {
          label: this.$t('pages.inventory.addMaterialModal.title'),
          value: 0
        },
        transmitter: {
          id: {
            label: this.$t('pages.inventory.addMaterialModal.forms.transmitter.id'),
            value: null
          },
          ttnId: {
            label: this.$t('pages.inventory.addMaterialModal.forms.transmitter.ttnId'),
            value: null
          },
          type: {
            label: this.$t('pages.inventory.addMaterialModal.forms.transmitter.type'),
            value: null
          },
          hygrometer: {
            label: this.$t('pages.inventory.addMaterialModal.forms.transmitter.hygrometerId'),
            value: null,
          },
          barometer: {
            label: this.$t('pages.inventory.addMaterialModal.forms.transmitter.barometerId'),
            value: null,
          },
          anemometer: {
            label: this.$t('pages.inventory.addMaterialModal.forms.transmitter.anemometerId'),
            value: null,
          },
          pyranometer: {
            label: this.$t('pages.inventory.addMaterialModal.forms.transmitter.pyranometerId'),
            value: null,
          },
          pluviometer: {
            label: this.$t('pages.inventory.addMaterialModal.forms.transmitter.pluviometerId'),
            value: null,
          },
          flowmeter: {
            label: this.$t('pages.inventory.addMaterialModal.forms.transmitter.flowmeterId'),
          }
        },
        //Sensors management
        sensor:{
          id: {
            label: this.$t('pages.inventory.addMaterialModal.forms.sensor.id'),
            value: null
          },
          type: {
            label: this.$t('pages.inventory.addMaterialModal.forms.sensor.type'),
            value: null
          }
        }
      },
    }
  },

  computed: {
    isWeatherStationSelected(){
      return (
        this.forms.transmitter.type.value === this.transmitterTypeWeatherStation ||
        this.forms.transmitter.type.value === this.transmitterTypeWeatherStationPyranometer ||
        this.forms.transmitter.type.value === this.transmitterTypeWeatherStationPluviometer ||
        this.forms.transmitter.type.value === this.transmitterTypeWeatherStationPyranometerAndPluviometer
      );
    },
    isFlowmeterSelected(){
      return (
        this.forms.transmitter.type.value === this.transmitterTypeFlowMeter
      );
    }
  },
  watch: {
    "forms.transmitter.id.value": debounce(function(baseId) {
      const NumberbaseId = Number(baseId);
      const isValidBaseId = !isNaN(NumberbaseId) && baseId !==null && baseId !== '';

      if (this.forms && this.forms.transmitter && this.forms.transmitter.id) {
        //Vérification si ID valide 
        if(!isValidBaseId){
          this.resetSensorIds();
          return;
        }
        //Type Station météo sélectionné
        if (this.isWeatherStationSelected) {
          // Remplissage automatique des champs ID
          this.forms.transmitter.hygrometer.value = NumberbaseId + 2;
          this.forms.transmitter.barometer.value = NumberbaseId + 3;
          this.forms.transmitter.anemometer.value = NumberbaseId + 1;

          // Station météo avec pyranomètre
          if (this.forms.transmitter.type.value === this.transmitterTypeWeatherStationPyranometer) {
            this.forms.transmitter.pyranometer.value = NumberbaseId + 4;
          }
          // Station météo avec pluviomètre
          if (this.forms.transmitter.type.value === this.transmitterTypeWeatherStationPluviometer) {
            this.forms.transmitter.pluviometer.value = NumberbaseId + 5;
          }
        }
        // Débitmètre
        else if(this.isFlowmeterSelected){
          this.forms.transmitter.flowmeter.value = NumberbaseId + 1; 
        }

        // Remplissage automatique du TTN ID
        if (NumberbaseId) {
          console.log("NumberID:", NumberbaseId);
          this.forms.transmitter.ttnId.value = `id-${NumberbaseId}`; 
        }
      } else {
        console.error("forms.transmitter ou forms.transmitter.id n'est pas défini");
      }
    },),

    //Changement du type d'émetteur
    "forms.transmitter.type.value": function(newType, oldType) {
      if(newType !== oldType){
        console.log('Type émetteur changé : ${oldType} -> ${newType}');
        this.resetSensorIds();
        this.resetTTnAndID();
      }
    }
  },

  methods: {
    //Réinitialisation des ID's des capteurs
    resetSensorIds(){
      if(this.forms && this.forms.transmitter){
        this.forms.transmitter.hygrometer.value = null;
        this.forms.transmitter.barometer.value = null;
        this.forms.transmitter.anemometer.value = null;
        this.forms.transmitter.pyranometer.value = null;
        this.forms.transmitter.pluviometer.value = null;
        this.forms.transmitter.flowmeter.value = null;
        console.log("IDs réinitialisés");
      }
    },
    //Réinitialisation de l'ID de l'émetteur et TTN-ID
    resetTTnAndID(){
      if(this.forms && this.forms.transmitter) {
        this.forms.transmitter.id.value = null;
        this.forms.transmitter.ttnId.value = null;
      }
    },
    /**
     * Callback called when a change occurred in the html material types select
     */
    materialTypeOnChange: function(){
      // Transmitter type chosen
      if(this.forms.materialType.value === this.materialTypes.transmitter.value){
        this.loadAllTransmitterTypes();
      }else if(this.forms.materialType.value === this.materialTypes.sensor.value){
        this.loadAllSensorTypes();
      }
    },
    /**
     * Fetch all available transmitter types. Fetch this data only one time if not already loaded
     */
    loadAllTransmitterTypes: function() {
      // Fetch data only one time
      if(this.transmitterTypes === null){
        apiGetRequest(API_TRANSMITTER_TYPES_ALL)
            .then((res) => {
              this.transmitterTypes = res.data
              // Set the default value to first item received
              this.forms.transmitter.type.value = this.transmitterTypes[0].id
            })
      }
    },
    /**
     * Fetch all available sensor types. Fetch this data only one time if not already loaded
     */
    loadAllSensorTypes: function() {
      // Fetch data only one time
      if(this.sensorTypes === null){
        apiGetRequest(API_SENSOR_TYPES_ALL)
            .then((res) => {
              // Keep only sensor who's not soldered to transmitter (like all sensor of a weather station)      
              this.sensorTypes = res.data.filter(st => !st.solderedToTransmitter) 
              // Set the default value to first item received
              this.forms.sensor.type.value = this.sensorTypes[0].id
            })
      }
    },
    /**
     * Called when we want to submit the transmitter form.
     * Store the new transmitter
     */
    submitTransmitterForm: function() {

      // Basic data to send
      let data = {
        id: this.forms.transmitter.id.value,
        ttnId: this.forms.transmitter.ttnId.value,
        typeId: this.forms.transmitter.type.value,
        isWeatherStation: false,
        isFlowMeter: false
        
      }

      // Add more elements if the chosen transmitter type with flow meter
      if(data.typeId === this.transmitterTypeFlowMeter){
        console.log('Création émetteur avec débitmètre');
        data.isFlowMeter = true
        data.flowmeterId =this.forms.transmitter.flowmeter.value
        console.log('Flowmeter create with ID:', data.flowmeterId);
        console.log('Payload envoyé au backend:', data);
      }

      // Add more elements if the chosen transmitter type is Weather Station
      if(data.typeId === this.transmitterTypeWeatherStation){
        data.isWeatherStation = true
        data.hygrometerId = this.forms.transmitter.hygrometer.value
        data.barometerId = this.forms.transmitter.barometer.value
        data.anemometerId = this.forms.transmitter.anemometer.value
        data.pluviometerId = -1
        data.pyranometerId = -2
      }
      // Add more elements if the chosen transmitter type is Weather Station with Pyranometer
      else if(data.typeId === this.transmitterTypeWeatherStationPyranometer){
        data.isWeatherStation = true
        data.hygrometerId = this.forms.transmitter.hygrometer.value
        data.barometerId = this.forms.transmitter.barometer.value
        data.anemometerId = this.forms.transmitter.anemometer.value
        data.pyranometerId = this.forms.transmitter.pyranometer.value
        data.pluviometerId = -1
      }
      // Add more elements if the chosen transmitter type is Weather Station with pluviometer
      else if(data.typeId === this.transmitterTypeWeatherStationPluviometer){
        data.isWeatherStation = true
        data.hygrometerId = this.forms.transmitter.hygrometer.value
        data.barometerId = this.forms.transmitter.barometer.value
        data.anemometerId = this.forms.transmitter.anemometer.value
        data.pluviometerId = this.forms.transmitter.pluviometer.value
        data.pyranometerId = -2
      }

      //Add more elements if the chosen transmitter type is Weather Station with pyranometer and pluviometer
      else if(data.typeId === this.transmitterTypeWeatherStationPyranometerAndPluviometer){
        data.isWeatherStation = true
        data.hygrometerId = this.forms.transmitter.hygrometer.value
        data.barometerId = this.forms.transmitter.barometer.value
        data.anemometerId = this.forms.transmitter.anemometer.value
        data.pyranometerId = this.forms.transmitter.pyranometer.value
        data.pluviometerId = this.forms.transmitter.pluviometer.value
      }

      apiPostRequest(API_USERS_TRANSMITTERS_STORE(this.userId), data)
          .then(() => {
            // Emit an event to notify the transmitter creation
            this.$emit("newTransmitterCreated", this.forms.transmitter.id.value)
            // Reset the form and toggle modal
            this.forms.materialType.value = 0
            this.forms.transmitter.id.value = null
            this.forms.transmitter.ttnId.value = null
            this.forms.transmitter.type.value = this.transmitterTypes[0].id
            this.toggle()
          })
          .catch((e) => {
            // Add custom error message for errors that comes from the backend checks
            if(errorResponseIsFormError(e)){
              const errors = {}
              const uniqueErrorLabel = this.$t('pages.inventory.addMaterialModal.forms.uniqueError')
              const distinctErrorLabel = this.$t('pages.inventory.addMaterialModal.forms.distinctIdsError')
              for (const errorKey in e.response.data.fields) {
                // Error when id already exists
                if(e.response.data.fields[errorKey] === 'unique'){
                  switch (errorKey) {
                    case "id":
                      errors[this.forms.transmitter.id.label] = uniqueErrorLabel
                      break
                    case "ttnId":
                      errors[this.forms.transmitter.ttnId.label] = uniqueErrorLabel
                      break
                    case "pyranometerId":
                      errors[this.forms.transmitter.pyranometer.label] = uniqueErrorLabel
                      break
                    case "hygrometerId":
                      errors[this.forms.transmitter.hygrometer.label] = uniqueErrorLabel
                      break
                    case "barometerId":
                      errors[this.forms.transmitter.barometer.label] = uniqueErrorLabel
                      break
                    case "anemometerId":
                      errors[this.forms.transmitter.anemometer.label] = uniqueErrorLabel
                      break
                    case "pluviometerId":
                      errors[this.forms.transmitter.pluviometer.label] = uniqueErrorLabel
                      break
                    case "flowmeterId":
                      errors[this.forms.transmitter.flowmeter.label] = uniqueErrorLabel
                      break
                  }
                }
                // Error for weather station sensors, all 4 ids must be different each other
                else if(e.response.data.fields[errorKey] === 'distinct') {
                  if(errorKey === 'weatherStationSensors'){
                    errors[this.forms.transmitter.pyranometer.label] = distinctErrorLabel
                    errors[this.forms.transmitter.hygrometer.label] = distinctErrorLabel
                    errors[this.forms.transmitter.barometer.label] = distinctErrorLabel
                    errors[this.forms.transmitter.anemometer.label] = distinctErrorLabel
                    errors[this.forms.transmitter.pluviometer.label] = distinctErrorLabel
                  }
                }
              }
              this.$refs.transmitterForm.setErrors(errors)
            } else {
              this.toggle()
            }
          })
    },
    /**
     * Called when we want to submit the sensor form
     * Store the new sensor
     */
    submitSensorForm: function() {
      apiPostRequest(API_USERS_SENSORS_STORE(this.userId), {
        id: this.forms.sensor.id.value,
        typeId: this.forms.sensor.type.value

      })
          .then(() => {
            // Emit an event to notify the sensor creation
            this.$emit("newSensorCreated", this.forms.sensor.id.value)
            // Reset the form and toggle modal
            this.forms.materialType.value = 0
            this.forms.sensor.id.value = null;
            this.forms.sensor.type.value = this.sensorTypes[0].id
            this.toggle()
          })
          .catch((e) => {
            // Add custom error message for fields that must be unique in DB
            if(errorResponseIsFormError(e)){
              const errors = {}
              for (const errorKey in e.response.data.fields) {
                if(errorKey === "id" && e.response.data.fields[errorKey] === 'unique'){
                  errors[this.forms.sensor.id.label] = this.$t('pages.inventory.addMaterialModal.forms.uniqueError')
                }
              }
              this.$refs.sensorForm.setErrors(errors)
            } else {
              this.toggle()
            }
          })
    }
  }
}
</script>

<style scoped>
.case-id-width {
  width: 10rem; 
}
</style>

