<template>
  <div>
    <div class="flex items-center mb-4">
      <!-- Barre de recherche -->
      <div class="flex-1">
        <input type="text" class="form__input w-64" placeholder="Rechercher..." v-model="searchTerm">
      </div>
      <div v-if="$store.getters.userIsAdmin">
        <router-link :to="{name:'user_create'}">
          <button class="btn btn--primary">{{$t('pages.user_index.create')}}</button>
        </router-link>
      </div>
    </div>

    <!-- Admins -->
    <div v-if="admins.isLoaded" class="card user-card">
      <h2 class="card__title">
        <i class="fas fa-user-shield mr-2"></i>{{ $t('pages.user_index.cards.admins.title', {'count': filteredAdmins.length }) }}
      </h2>
      <data-table v-if="filteredAdmins.length > 0" :columns="admins.columns" :data="filteredAdmins" />
      <p v-else>No admins found.</p>
    </div>

    <!-- Farmers -->
    <div v-if="farmers.isLoaded" class="card user-card mt-4">
      <h2 class="card__title">
        <i class="fas fa-seedling mr-2"></i>{{ $t('pages.user_index.cards.farmers.title', {'count': filteredFarmers.length }) }}
      </h2>
      <data-table v-if="filteredFarmers.length > 0" :columns="farmers.columns" :data="filteredFarmers" />
      <p v-else>No farmers found.</p>
    </div>
  </div>
</template>

<script>
import DataTable from "../../../components/general/datatable/data-table";
import { apiGetRequest } from "../../../utils";
import { API_USERS_ALL } from "../../../utils/constants";

export default {
  name: "UsersIndex",
  components: { DataTable },
  data() {
    return {
      admins: {
        isLoaded: false,
        data: [],
        columns: {
          firstname: this.$t('words.firstname'),
          lastname: this.$t('words.lastname'),
          email: this.$t('words.email'),
          street: this.$t('words.street'),
          npaCity: this.$t('words.npa-city'),
          country: this.$t('words.country'),
          nbDevices: this.$t('words.nbDevices'),
          actions: this.$t('words.actions'),
        },
      },
      farmers: {
        isLoaded: false,
        data: [],
        columns: {
          firstname: this.$t('words.firstname'),
          lastname: this.$t('words.lastname'),
          email: this.$t('words.email'),
          street: this.$t('words.street'),
          npaCity: this.$t('words.npa-city'),
          country: this.$t('words.country'),
          empty: "",
          nbCultures: this.$t('words.nbCultures'),
          nbDevices: this.$t('words.nbDevices'),
          actions: this.$t('words.actions'),
        },
      },
      searchTerm: ""
    }
  },
  computed: {
  //Barre de recherche 
    filteredAdmins() {
      const term = this.searchTerm.trim().toLowerCase();
      if (!term) return this.admins.data;

      return this.admins.data.filter(admin => {
        return ["firstname", "lastname", "country", "street", "npaCity"].some(key =>
          admin[key]?.toLowerCase().includes(term)
        );
      });
    },
    filteredFarmers() {
      const term = this.searchTerm.trim().toLowerCase();
      if (!term) return this.farmers.data;

      return this.farmers.data.filter(farmer => {
        return ["firstname", "lastname", "country", "street", "npaCity"].some(key =>
          farmer[key]?.toLowerCase().includes(term)
        );
      });
    },
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      apiGetRequest(API_USERS_ALL)
        .then(res => {
          this.admins.data = res.data.admins.map(a => {
            a.nbDevices = a.nbSensors + a.nbTransmitters;
            a.npaCity = `${a.npa} ${a.city}`;
            a.country = a.country || 'N/A'; 
            a.actions = {
              see: {
                to: { name: 'users_inventory', params: { userIdProps: a.id } },
                text: this.$t('pages.user_index.cards.admins.seeBtn')
              }
            };
            if (a.id !== this.$store.state.user.id) {
              a.actions['edit'] = {
                to: { name: 'user_update', params: { userId: a.id } },
                text: this.$t('words.edit')
              };
            }
            return a;
          });
          this.admins.isLoaded = true;

          this.farmers.data = res.data.farmers.map(f => {
            f.nbDevices = f.nbSensors + f.nbTransmitters;
            f.npaCity = `${f.npa} ${f.city}`;
            f.country = f.country || 'N/A'; 
            f.actions = {
              see: {
                to: { name: 'farmer_show', params: { id: f.id } },
                text: this.$t('words.see')
              },
              edit: {
                to: { name: 'user_update', params: { userId: f.id } },
                text: this.$t('words.edit')
              }
            };
            return f;
          });
          this.farmers.isLoaded = true;
        })
        .catch(error => {
          console.error('Error loading users:', error);
        });
    }
  }
}
</script>

<style scoped>
.form__input.w-64 {
  width: 30rem; /* longueur bande de recherche */
}

/* Cartes (Admins et Farmers) */
.user-card {
  border-radius: 10px; /* Bords arrondis */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombre */
  padding: 20px; 
  background-color: #fff; /* Fond blanc */
  border: 1px solid #e0e0e0; /* Bordure */
}

.card__title {
  font-size: 1.4rem; /* Taille du texte */
  font-weight: 700; /* Texte en gras */
  color: #103c79; /* Couleur du texte */
  margin-bottom: 16px; /* Espacement sous le titre */
  text-decoration: none;
}
</style>