import Inventory from "../views/app/Inventory";
import CultureIndex from "../views/app/culture/CultureIndex";
import {ADMIN_ROLE, FARMER_ROLE} from "../utils/constants";
import CultureShow from "../views/app/culture/CultureShow";
import TransmitterShow from "../views/app/transmitter/TransmitterShow";
import SensorShow from "../views/app/sensor/SensorShow";
import CultureEvents from "../views/app/culture/CultureEvents";
import PendingEventIndex from "../views/app/event/PendingEventIndex";
import FarmerShow from "../views/app/user/farmer/FarmerShow";
import CultureCreate from "../views/app/culture/CultureCreate";
import CultureHistoryIndex from "../views/app/history/cultures/CultureHistoryIndex";
import CultureHistoryShow from "../views/app/history/cultures/CultureHistoryShow";
import SeriesHistoryShow from "../views/app/history/series/SeriesHistoryShow";
import SeriesHistoryEdit from "../views/app/history/series/SeriesHistoryEdit";
import Transfer from "../views/app/Transfer";
import UsersIndex from "../views/app/user/UsersIndex";
import CultureEdit from "../views/app/culture/CultureEdit";
import CultureMapEdit from "../views/app/culture/CultureMapEdit";
import ServerLogs from "../views/app/ServerLogs";
import ExportCSV from "../views/app/csv/ExportCSV";
import UserCreate from "@/views/app/user/UserCreate.vue";
import UserUpdate from "@/views/app/user/UserUpdate.vue";
import FirstConnection from "@/views/app/FirstConnection.vue";
import Profile from "@/views/app/Profile.vue";
import SensorEdit from "../views/app/sensor/SensorEdit.vue";
import TransmitterEdit from "../views/app/transmitter/TransmitterEdit.vue";

const appRoutes = [
  {
    name: 'inventory',
    path: '/inventory',
    component: Inventory,
    props: true,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE, FARMER_ROLE]
    },
  },
  {
    name: 'transfer',
    path: '/transfer',
    component: Transfer,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE]
    },
  },
  // Farmers
  {
    name: 'farmer_show',
    path: '/farmers/:id',
    component: FarmerShow,
    props: true,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE]
    }
  },
  {
    name: 'farmer_culture_create',
    path: '/farmers/:farmerId/cultures/create',
    component: CultureCreate,
    props: true,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE]
    }
  },
  // Cultures
  {
    name: 'culture_index',
    path: '/cultures',
    component: CultureIndex,
    props: true,
    meta: {
      auth: true,
      roles: [FARMER_ROLE]
    }
  },
  {
    name: 'culture_create',
    path: '/cultures/create',
    component: CultureCreate,
    props: true,
    meta: {
      auth: true,
      roles: [FARMER_ROLE]
    }
  },
  {
    name: 'culture_show',
    path: '/cultures/:id',
    component: CultureShow,
    props: true,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE, FARMER_ROLE]
    }
  },
  {
    name: 'culture_edit',
    path: '/cultures/:id/edit',
    component: CultureEdit,
    props: true,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE, FARMER_ROLE]
    }
  },
  {
    name: 'culture_events',
    path: '/cultures/:id/events',
    component: CultureEvents,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE, FARMER_ROLE]
    }
  },
  {
    name: 'culture_map_edit',
    path: '/cultures/:id/map/edit',
    component: CultureMapEdit,
    props: true,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE, FARMER_ROLE]
    }
  },
  // Transmitters
  {
    name: 'transmitter_show',
    path: '/transmitters/:id',
    component: TransmitterShow,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE, FARMER_ROLE]
    }
  },
  // Sensors
  {
    name: 'sensor_show',
    path: '/sensors/:id',
    component: SensorShow,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE, FARMER_ROLE]
    }
  },
  //Edit Sensor
  {
    name: 'sensor_edit',
    path: '/sensors/:id/edit',
    component: SensorEdit,
    props: true,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE]
    }
  },
  //Edit Transmitter
  {
    name: 'transmitter_edit',
    path: '/transmitter/:id/edit',
    component: TransmitterEdit,
    props: true,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE]
    }
  },

  // Events
  {
    name: 'pendingEvent_index',
    path: '/events/pending',
    component: PendingEventIndex,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE, FARMER_ROLE]
    }
  },
  // History
  {
    name: 'history_cultureIndex',
    path: '/history/cultures',
    component: CultureHistoryIndex,
    meta: {
      auth: true,
      roles: [FARMER_ROLE]
    }
  },
  {
    name: 'history_cultureShow',
    path: '/history/cultures/:cultureIdProps',
    component: CultureHistoryShow,
    props: true,
    meta: {
      auth: true,
      roles: [FARMER_ROLE]
    }
  },
  {
    name: 'history_farmerCultureIndex',
    path: '/history/farmers/:farmerIdProps/cultures',
    component: CultureHistoryIndex,
    props: true,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE]
    }
  },
  {
    name: 'history_farmerCultureShow',
    path: '/history/farmers/:farmerIdProps/cultures/:cultureIdProps',
    component: CultureHistoryShow,
    props: true,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE]
    }
  },
  {
    name: 'history_seriesShow',
    path: '/history/series/:measureUuid',
    component: SeriesHistoryShow,
    props: true,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE, FARMER_ROLE]
    }
  },
  {
    name: 'history_seriesEdit',
    path: '/history/series/:measureUuid/edit/:measureTypeId',
    component: SeriesHistoryEdit,
    props: true,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE, FARMER_ROLE]
    }
  },
  // Users
  {
    name: 'user_index',
    path: '/users',
    component: UsersIndex,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE]
    }
  },
  {
    name: 'user_create',
    path: '/users/create',
    component: UserCreate,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE]
    }
  },
  {
    name: 'user_update',
    path: '/users/:userId',
    component: UserUpdate,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE, FARMER_ROLE]
    }
  },
  {
    name: 'users_inventory',
    path: '/users/:userIdProps/inventory',
    component: Inventory,
    props: true,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE]
    }
  },
  {
    name: 'first_connection',
    path: '/first/connection',
    component: FirstConnection,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE, FARMER_ROLE]
    }
  },
  {
    name: 'profile',
    path: '/profile/:userId',
    component: Profile,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE, FARMER_ROLE]
    }
  },
  // Server Logs
  {
    name: 'server_logs',
    path: '/logs',
    component: ServerLogs,
    props: false,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE],
      disabled: process.env.VUE_APP_SERVER_LOGS_DISPLAYABLE !== 'true'
    },
  },
  {
    name: 'export_csv',
    path: '/csv/export',
    component: ExportCSV,
    props: true,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE, FARMER_ROLE]
    }
  }
]

export default appRoutes
