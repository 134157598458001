<template>
  <div v-if="culture.isLoaded">

    <!-- Card culture details -->
    <div class="card mt-4">
      <h2 class="card__title">{{ $t('pages.history_cultureShow.cards.culture.title') }} "<b>{{ culture.data.name }}</b>"</h2>

      <div class="flex justify-items-start">
        <div>
          <div>
            <i class="fas fa-calendar-alt mr-2"></i>{{ $t('pages.history_cultureShow.cards.culture.archivedAt') }}: 
          </div>
          <div>
            <i class="fas fa-seedling mr-2"></i>{{ $t('pages.history_cultureShow.cards.culture.cultivatedVariety') }}: 
          </div>
          <div>
            <i class="fas fa-calendar-check mr-2"></i>{{ $t('pages.history_cultureShow.cards.culture.nouaisonDate') }}: 
          </div>
          <div>
            <i class="fas fa-broadcast-tower mr-2"></i>{{ $t('pages.history_cultureShow.cards.culture.nbTransmitters') }}: 
          </div>
          <div>
            <i class="fas fa-microchip mr-2"></i>{{ $t('pages.history_cultureShow.cards.culture.nbSensors') }}: 
          </div>
        </div>

        <div class="ml-4 font-semibold">
          <div>{{ culture.data.archivedAt !== null ? displayDateTime(culture.data.archivedAt) : $t('pages.history_cultureShow.cards.culture.measuring') }}</div>
          <div>{{ $t('backend_trans_keys.'+culture.data.typeTransKey) }} ({{ $t('backend_trans_keys.' + culture.data.varietyTransKey) }})</div>
          <div>{{ culture.data.nouaisonDate === null ? '-' : displayDate(culture.data.nouaisonDate) }}</div>
          <div>{{ culture.data.nbTransmitters }}</div>
          <div>{{ culture.data.nbSensors }}</div>
        </div>
      </div>
    </div>

    <!-- CSV export -->
    <div class="mt-4" v-if="series.isLoaded">
      <btn-with-loader class="mt-4" :loading="loadingExportCSV" :class-btn-type="'btn--primary'" @clicked="downloadCsv"><i class="fas fa-download mr-2"></i>CSV</btn-with-loader>
    </div>

    <!-- Card measurement series -->
    <div class="card mt-4">
      <h2 class="card__title">{{ $t('pages.history_cultureShow.cards.series.title', {count: culture.data.nbMeasurementSeries}) }}</h2>
      <div v-if="!series.isLoaded">
        <i class="fas fa-spinner fa-spin text-lg"></i>
        <span class="ml-4 text-sm">{{ $t('pages.history_cultureShow.cards.series.loadingMsg') }}</span>
      </div>
      <div v-else>
        <data-table :columns="series.columns" :data="series.data" />
      </div>
    </div>

    <div>
      <CultureChart :culture-id="cultureIdProps" :is-history="true"/>
    </div>

  </div>

</template>

<script>
import {apiGetRequest, displayDateByLocale, generateDownloadCsvLink,apiGetRequestArray} from "../../../../utils";
import {
  API_HISTORY_FARMERS_CULTURES_SERIES, API_HISTORY_FARMERS_CULTURES_SERIES_CSV_EXPORT,
  API_HISTORY_FARMERS_CULTURES_SHOW,
} from "../../../../utils/constants";
import DataTable from "../../../../components/general/datatable/data-table";
import BtnWithLoader from "../../../../components/general/btn-with-loader";
import CultureChart from "@/components/model/CultureChart.vue";

export default {
  name: "CultureHistoryShow",
  components: {CultureChart, DataTable, BtnWithLoader},
  props: {
    farmerIdProps: {
      type: [Number, String],
      required: false // Only required for ADMIN route, for FARMER we use current user ID (see farmerId data)
    },
    cultureIdProps: {
      type: [Number, String],
      required: true
    }
  },
  data: function() {
    return {
      farmerId: this.$store.getters.userIsAdmin ? this.farmerIdProps : this.$store.state.user.id,
      cultureId: this.cultureIdProps,
      culture: {
        isLoaded: false,
        data: null
      },
      series: {
        isLoaded: false,
        columns: {
          beginAt: this.$t('pages.history_cultureShow.cards.series.columns.measuresBeginAt'),
          endAt: this.$t('pages.history_cultureShow.cards.series.columns.measuresEndAt'),
          transmitterIds: this.$t('pages.history_cultureShow.cards.series.columns.usedTransmitters'),
          sensorIds: this.$t('pages.history_cultureShow.cards.series.columns.usedSensors'),
          nbMeasures: this.$t('pages.history_cultureShow.cards.series.columns.nbMeasures'),
          actions: this.$t('words.actions')
        },
        data: []
      },
      loadingExportCSV: false
    }
  },
  mounted() {
    this.loadCulture()
  },
  methods: {
    /**
     * Load details about the culture
     */
    loadCulture: function() {
      this.culture.isLoaded = false
      apiGetRequest(API_HISTORY_FARMERS_CULTURES_SHOW(this.farmerId, this.cultureId))
          .then((res) => {
            this.culture.data = res.data
            this.culture.isLoaded = true
            this.loadSeries()
          })
    },
    /**
     * Load all measurement series for the current culture
     */
    loadSeries: function() {
      this.series.isLoaded = false
      apiGetRequest(API_HISTORY_FARMERS_CULTURES_SERIES(this.farmerId, this.cultureId))
        .then((res) => {
          this.series.data = res.data.map((serie) => {
            serie.beginAt = serie.beginAt === null ? '-' : this.displayDateTime(serie.beginAt)
            serie.endAt = serie.endAt === null ? '-' : this.displayDateTime(serie.endAt)
            serie.transmitterIds = serie.transmitterIds.length === 0 ? '-' : serie.transmitterIds.join(', ')
            serie.sensorIds = serie.sensorIds.length === 0 ? '-' : serie.sensorIds.join(', ')
            serie.actions = {
              see: {
                to: { name: 'history_seriesShow', params: { measureUuid: serie.measureUuid } },
                text: this.$t('words.see')
              }
            }
            return serie
          })

          this.series.isLoaded = true
        })
    },
    /**
     * Display date by moment.js locale
     * @param dateTime The date time to display
     * @returns {string} A strig representation of the date, well formatted by the current locale
     */
    displayDateTime: function(dateTime) {
      return displayDateByLocale(dateTime)
    },
    /**
     * Display date by moment.js locale
     * @param date The date to display
     * @returns {string} A strig representation of the date, well formatted by the current locale
     */
    displayDate: function(date) {
      return displayDateByLocale(date, true)
    },
    /**
     * Generate csv export that contains all measures for all measurement series of the current culture
     * For ajax call, we need to recreate a link and simulate a click to download the real file.
     */
    downloadCsv: function() {
      if(!this.loadingExportCSV){
        this.loadingExportCSV = true
        apiGetRequestArray(API_HISTORY_FARMERS_CULTURES_SERIES_CSV_EXPORT(this.farmerId, this.cultureId))
            .then((res) => {
              const link = generateDownloadCsvLink(res.data, res.headers['x-filename'])
              link.click();
            })
            .finally(() => this.loadingExportCSV = false)
      }
    },
  }
}
</script>

<style scoped>
.card {
  border: 1px solid #ddd; /* Bordure */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Ombre */
  border-radius: 8px; 
  padding: 15px; 
}

.card__title {
  font-size: 1.05rem; /* Taille du texte */
  margin-bottom: 10px;
  text-decoration: "";
}

.mt-4 {
  margin-top: 1rem; /* Marge */
}

/* Ombre plus prononcée au survol de la section */
.card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
}
</style>
