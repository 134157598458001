import { render, staticRenderFns } from "./CultureHistoryShow.vue?vue&type=template&id=82aac75e&scoped=true"
import script from "./CultureHistoryShow.vue?vue&type=script&lang=js"
export * from "./CultureHistoryShow.vue?vue&type=script&lang=js"
import style0 from "./CultureHistoryShow.vue?vue&type=style&index=0&id=82aac75e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82aac75e",
  null
  
)

export default component.exports