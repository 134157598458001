<template>
  <div>
    <div v-if="isLoaded.cultures" class="card mt-4">
      <div class="card__title-container">
        <!-- Icône ajoutée avant le titre -->
        <i class="fas fa-leaf card__icon"></i>
        <h2 class="card__title">{{ $t('pages.history_cultureIndex.culturesTable.title', {count: culturesTable.data.length}) }}</h2>
      </div>
      <data-table :columns="culturesTable.columns" :data="culturesTable.data" />
    </div>
  </div>
</template>

<script>
import {apiGetRequest, displayDateByLocale} from "../../../../utils";
import {API_HISTORY_FARMERS_CULTURES} from "../../../../utils/constants";
import DataTable from "../../../../components/general/datatable/data-table";

export default {
  name: "CultureHistoryIndex",
  components: {DataTable},
  props: {
    farmerIdProps: {
      type: [Number, String],
      required: false // Only required for ADMIN route, for FARMER we use current user ID (see farmerId data)
    }
  },
  data: function() {
    return {
      farmerId: this.$store.getters.userIsAdmin ? this.farmerIdProps : this.$store.state.user.id,
      isLoaded: {
        cultures: false
      },
      culturesTable: {
        columns: {
          archivedAt: this.$t('pages.history_cultureIndex.culturesTable.columns.archivedAt'),
          name: this.$t('pages.history_cultureIndex.culturesTable.columns.name'),
          type: this.$t('pages.history_cultureIndex.culturesTable.columns.cultivatedVariety'),
          nouaisonDate: this.$t('pages.history_cultureIndex.culturesTable.columns.nouaisonDate'),
          nbTransmitters: this.$t('pages.history_cultureIndex.culturesTable.columns.nbTransmitters'),
          nbSensors: this.$t('pages.history_cultureIndex.culturesTable.columns.nbSensors'),
          nbMeasurementSeries: this.$t('pages.history_cultureIndex.culturesTable.columns.nbMeasurementSeries'),
          actions: this.$t('words.actions')
        },
        data: []
      }
    }
  },
  mounted() {
    this.loadCultures()
  },
  methods: {
    /**
     * Load all history cultures
     */
    loadCultures: function() {
      this.isLoaded.cultures = false

      apiGetRequest(API_HISTORY_FARMERS_CULTURES(this.farmerId))
        .then((res) => {
          this.culturesTable.data = res.data.map((c) => {
            c.archivedAt = c.archivedAt === null ? this.$t('pages.history_cultureIndex.culturesTable.inProgress') : this.displayDateTime(c.archivedAt)
            c.type = `${this.$t('backend_trans_keys.'+c.typeTransKey)} (${this.$t('backend_trans_keys.'+c.varietyTransKey)})`
            c.nouaisonDate = c.nouaisonDate === null ? '-' : this.displayDate(c.nouaisonDate)

            const toObj = this.$store.getters.userIsAdmin ? {name: 'history_farmerCultureShow', params: {farmerIdProps: this.farmerId, cultureIdProps: c.id}} : {name: 'history_cultureShow', params: {cultureIdProps: c.id}}
            c.actions = {
              see: {
                to: toObj,
                text: this.$t('words.see')
              }
            }

            return c
          })
          this.isLoaded.cultures = true
        })
    },
    /**
     * Display date by moment.js locale
     * @param dateTime The date time to display
     * @returns {string} A strig representation of the date, well formatted by the current locale
     */
    displayDateTime: function(dateTime) {
      return displayDateByLocale(dateTime)
    },
    /**
     * Display date by moment.js locale
     * @param date The date to display
     * @returns {string} A string representation of the date, well formatted by the current locale
     */
    displayDate: function(date) {
      return displayDateByLocale(date, true)
    }
  }
}
</script>

<style scoped>
.card {
  border: 1px solid #ddd; /* Bordure */
  border-radius: 8px; /* Coins arrondis */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Ombre */
  padding: 20px; 
  background-color: #fff; 
  transition: all 0.3s ease; 
}

.card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.card__title-container {
  display: flex; 
  align-items: center; 
  margin-bottom: 20px; /* Espace entre le titre et la ligne */
}

.card__icon {
  font-size: 1.1rem; /* Taille de l'icône */
  color: #4CAF50; /* Couleur */
  margin-right: 10px; /* Espacement entre l'icône et le titre */
  vertical-align: middle; 
}

.card__title {
  font-size: 1.2rem; /* Taille du texte */
  font-weight: bold;
  margin-bottom: 0; 
  text-decoration: none;
  color: #333; 
  line-height: 1.4; 
}

.mt-4 {
  margin-top: 20px; 
}
</style>
