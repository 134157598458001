<template>
  <div class="w-full">
    <div class="card">
      <div v-if="isUserAdmin && !profile" class="flex flex-row-reverse">
        <button @click="resetPassword" class="btn btn--success ml-3">
          <i class="fas fa-solid fa-key"></i> {{ $t('pages.user_update.reset-password') }}
        </button>
        <button @click="toggleDeleteUserModal" class="btn btn--danger">
          <i class="fas fa-solid fa-trash"></i> {{ $t('pages.user_update.delete.button') }}
        </button>
      </div>
      <UserForm 
        v-if="userInfo && Object.keys(userInfo).length > 0"   
        :on-submit="onSubmitUpdate" 
        :user-info="userInfo" 
        :is-edit="true" 
        :errors-form="errorsForm" 
        :profile="profile" 
      />
    </div>
    <Modal :visible="modalVisible" :toggle="toggleModal">
      <h2 class="text-lg mb-2">{{ $t('pages.user_update.success') }}</h2>
      <div>{{ $t('pages.user_update.copy-password') }}</div>
      <h1 class="text-xl text-center font-bold m-5">{{ newUserPassword }}</h1>
      <div class="text-center">
        <button @click="toggleModal" class="mt-6 btn btn--success">{{ $t('pages.user_create.modal-ok-password') }}</button>
      </div>
    </Modal>
    <Modal :visible="modalDeleteUserVisible" :toggle="toggleDeleteUserModal">
      <h2 class="text-lg mb-2">{{ $t('pages.user_update.delete.modal-title') }}</h2>
      <div>{{ $t('pages.user_update.delete.modal-text') }}</div>
      <div class="flex justify-between w-full">
        <button @click="toggleDeleteUserModal" class="mt-6 btn btn--danger">{{ $t('pages.user_update.delete.cancel') }}</button>
        <button @click="deleteUser" class="mt-6 btn btn--success">{{ $t('pages.user_update.delete.confirm') }}</button>
      </div>
    </Modal>
  </div>
</template>

<script>
import UserForm from "@/views/app/user/UserForm.vue";
import { apiGetRequest, apiPostRequest, displayAlertSuccess, errorResponseIsFormError } from "@/utils";
import { API_USERS_DELETE, API_USERS_RESET_PASSWORD, API_USERS_SPECIFIC_SHOW, API_USERS_UPDATE } from "@/utils/constants";
import Modal from "@/components/modals/modal.vue";

export default {
  name: "UserUpdate",
  components: { Modal, UserForm },
  props: {
    profile: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  mounted() {
    this.fetchUserInfo();
  },
  data() {
    return {
      userId: this.$route.params.userId,
      userInfo: {},
      errorsForm: {},
      newUserPassword: "",
      modalVisible: false,
      modalDeleteUserVisible: false,
    };
  },
  computed: {
    isUserAdmin() {
      return this.$store.getters.userIsAdmin;
    }
  },
  methods: {
    onSubmitUpdate(data) {
      apiPostRequest(API_USERS_UPDATE(this.userId), data)
        .then((res) => {
          this.userInfo = res.data;
          displayAlertSuccess(this.$t('pages.user_update.success'));
        })
        .catch((e) => {
          if (errorResponseIsFormError(e)) {
            const errors = {};
            for (const errorKey in e.response.data.fields) {
              if (e.response.data.fields[errorKey] === 'unique') {
                switch (errorKey) {
                  case "email":
                    errors[this.$t('words.email')] = this.$t('pages.user_create.error.emailUnique');
                    break;
                }
              }
            }
            this.errorsForm = errors;
          }
        });
    },
    fetchUserInfo() {
      apiGetRequest(API_USERS_SPECIFIC_SHOW(this.userId))
        .then((res) => {
          this.userInfo = res.data;
        });
    },
    resetPassword() {
      apiPostRequest(API_USERS_RESET_PASSWORD(this.userId), null)
        .then((res) => {
          this.newUserPassword = res.data;
          this.modalVisible = !this.modalVisible;
        });
    },
    deleteUser() {
      apiPostRequest(API_USERS_DELETE(this.userId), null)
        .then(() => {
          displayAlertSuccess(this.$t('pages.user_update.delete.success'));
          this.$router.push({ name: 'user_index' });
        });
    },
    toggleModal() {
      this.modalVisible = !this.modalVisible;
      displayAlertSuccess(this.$t('pages.user_update.success-reset-password'));
    },
    toggleDeleteUserModal() {
      this.modalDeleteUserVisible = !this.modalDeleteUserVisible;
    },
  }
};
</script>

<style scoped>
.card {
  padding: 1.5rem;
  background-color: #ffffff; 
  border-radius: 8px; /* Bords arrondis */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre */
  border: 1px solid #e0e0e0; /* Bordure */
}


</style>